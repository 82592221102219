<template>
  <v-container fluid>
    <FacilitiesTabs
        :configurations="configurations"
        :venue-service-id="venueServiceId"
        :venue-services="venueServices"
        @serviceChange="serviceChange"
        @update:venueServiceId="(value)=>venueServiceId = value"
        :key="venueServiceId"
    />

    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>
    <v-container style="max-width: 85%">
      <v-row dense class="mt-4">
        <v-col>
          <h3  class="text-base font-bold black-text">{{isTicket ? 'Ticket Details' :'Time Rates'}}</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-form ref="form">
            <v-card
                :style="cardStyle"
                v-for="(product, index) in rentalProducts"
                :key="index"
                class="mb-6 rounded shadow-0 bordered"
            >
              <v-card-text>
                <v-row dense>


                  <v-col cols="12">
                    <div class="d-flex gap-x-5 ">
                      <div style="min-width: 200px; min-height: 200px ; aspect-ratio: 1/1">
                        <image-uploader
                            :height="220"
                            :defaultImage="'ground'"
                            ref="image_upload"
                            message-text=""
                            text="Ticket Image"
                            @upload="
                            (data) => {
                              product.file = data;
                            }
                          "
                            @remove="
                            () => {
                              product.image = null;
                              product.data_url = null;
                            }
                          "
                            @result="
                            (dUrl) => {
                              product.data_url = dUrl;
                              $forceUpdate();
                            }
                          "
                            :image_path="product.image"
                        ></image-uploader>
                      </div>
                      <div>
                        <v-row dense class="mt-3">
                          <v-col cols="12" class="border-bottom">
                            <h3  class="text-base font-bold black-text">General Information:</h3>
                          </v-col>

                          <v-col md="6">
                            <label for="">Product Name*</label>
                            <v-text-field
                                outlined
                                required
                                background-color="#fff"
                                v-model="product.name"
                                hide-details="auto"
                                :rules="[(v) => !!v || 'Product name is required']"
                                class="q-text-field shadow-0"
                                dense
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <label for="">Product Name (Ar)</label>
                            <v-text-field
                                outlined
                                background-color="#fff"
                                v-model="product.ar_name"
                                hide-details="auto"
                                class="q-text-field shadow-0"
                                dense
                            ></v-text-field>
                          </v-col>

                          <v-col md="3">
                            <label for="">Price (Pre Tax)*</label>
                            <v-text-field
                                outlined
                                hide-details="auto"
                                rows="2"
                                :prefix="currencyCode"
                                background-color="#fff"
                                required
                                v-model="product.pre_tax_price"
                                @change="calculateTaxVariation(index, $event, 'pre')"
                                class="q-text-field shadow-0"
                                dense


                                :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                  ]"
                            ></v-text-field>
                          </v-col>



                          <v-col md="3">
                            <label for="">Tax*</label>

                            <v-select
                                v-model="product.tax_type_id"
                                item-value="value"
                                item-text="text"
                                hint="Required tax"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="[(v) => !!v || 'Tax type is required']"
                                :items="taxTypes"
                                hide-details="auto"
                                outlined
                                @change="taxChange(index)"
                                background-color="#fff"
                                class="q-autocomplete shadow-0"
                                dense

                            ></v-select>
                          </v-col>


                          <v-col md="3">
                            <label for="">Price (Post Tax)*</label>
                            <v-text-field
                                hide-details="auto"
                                outlined
                                rows="2"
                                :prefix="currencyCode"
                                background-color="#fff"
                                required
                                @change="calculateTaxVariation(index, $event, 'post')"
                                v-model="product.total_price"
                                class="q-text-field shadow-0"
                                dense

                                :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                  ]"
                            ></v-text-field>
                          </v-col>


                          <v-col md="3">
                            <label for="">Duration</label>
                            <v-select
                                required
                                v-model="product.duration"
                                hint="Duration"
                                item-text="text"
                                item-value="value"
                                outlined
                                hide-details="auto"
                                background-color="#fff"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :items="duration"
                                :rules="[(v) => !!v || 'Duration is required']"
                                class="q-autocomplete shadow-0"
                                dense

                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row dense class="border-bottom mt-4">
                  <v-col cols="12" md="12" lg="12" >
                    <h3  class="text-base font-bold black-text">Ticket Configuration:</h3>
                  </v-col>
                </v-row>

                <div v-if="isTicket == 1" class="mb-3 d-flex align-start gap-x-8" >
                  <div>
                    <label for="" class="d-block mt-4 mb-3">Select Package Type</label>
                    <v-radio-group class="custom-radio-group"
                                   v-model="product.ticket_type"
                                   row
                                   :rules="radioGroupRules"
                                   hide-details="auto"
                    >
                      <v-radio v-for="(pkg , i) in packageTypes" :key="i"
                               :label="pkg.name"
                               :value="pkg.type"
                               color="red"
                               class="custom-radio"

                      />

                    </v-radio-group>
                  </div>
                  <div
                      v-if="product.ticket_type === 'G'"

                  >
                    <label for="" class="d-block mt-4 mb-3">Participant count*</label>
                    <v-text-field
                        required
                        outlined
                        dense
                        background-color="#fff"
                        v-model="product.participant_count"
                        :rules="[
                        (v) => !!v || 'Participant count',
                        (v) => !isNaN(v) || 'Participant count must be Number',
                      ]"
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        style="width: 110px"
                    ></v-text-field>
                  </div>
                </div>
                <div class="d-flex gap-x-5 flex-wrap align-end" >
                  <div v-if="isEnableOpenDated && isTicket == 1">
                    <v-checkbox
                        v-model="product.is_open_dated"
                        :false-value="0"
                        :true-value="1"
                        label="Is Open Dated"
                        class="qp-custom-checkbox"
                        :ripple="false"
                    ></v-checkbox>
                  </div>

                  <div
                      v-if="
                  isEnableOpenDated &&
                    isTicket == 1 &&
                    product.is_open_dated == 1
                "
                  >
                    <label for="">Expiry days</label>
                    <v-text-field
                        outlined
                        background-color="#fff"
                        v-model="product.expiry_days"
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        dense
                    ></v-text-field>
                  </div>
                  <div v-if="isTicket == 1">
                    <v-checkbox
                        v-model="product.specific_day"
                        :false-value="0"
                        :true-value="1"
                        label="Specific Day"
                        class="qp-custom-checkbox"
                        :ripple="false"
                    ></v-checkbox>
                  </div>
                  <div
                      v-if="product.specific_day == '1' && isTicket == 1"
                  >
                    <label for="">Days Applicable</label>
                    <v-select
                        :items="weekdays"
                        item-text="name"
                        @change="rentalChange(index, $event)"
                        item-value="bit_value"
                        multiple
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        validate-on-blur
                        dense
                        :rules="[
                        (v) => v.length > 0 || 'Package days is required',
                      ]"
                        v-model="product.weekdays_value"
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        style="width: 250px"
                    >
                      <template
                          v-if="
                          product.weekdays_value &&
                            weekdays.length == product.weekdays_value.length
                        "
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">All Days</span>
                      </template>
                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index == 0">{{ item.name }}</span>
                        <span v-if="index == 1">, {{ item.name }}</span>
                        <span v-if="index == 2">, ...</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle(index)">
                          <v-list-item-action>
                            <v-icon
                                :color="
                                product.weekdays_value &&
                                product.weekdays_value.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >{{ getIcon(index) }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All Days</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>
                  <div>
                    <v-checkbox
                        v-model="product.benefit_excluded"
                        :false-value="0"
                        :true-value="1"
                        label="Exclude from promotions"
                        class="qp-custom-checkbox"
                        :ripple="false"

                    ></v-checkbox>
                  </div>
                  <div v-if="isTicket == 1">
                    <v-checkbox
                        v-model="product.show_end_time"
                        :false-value="0"
                        :true-value="1"
                        label="Show End Time"
                        class="qp-custom-checkbox"
                        :ripple="false"

                    ></v-checkbox>
                  </div>
                  <div v-if="isTicket == 1">
                    <v-checkbox
                        v-model="product.duplicate_booking"
                        :false-value="0"
                        :true-value="1"
                        label="Duplicate booking"
                        class="qp-custom-checkbox"
                        :ripple="false"

                    ></v-checkbox>
                  </div>
                  <div
                      v-if="product.duplicate_booking == '1'" >
                    <label for="">Duplicate Duration</label>
                    <v-select
                        v-model="product.duplicate_booking_duration"
                        style="max-width: 170px"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        :items="duration"
                        dense
                        :rules="[(v) => !!v || 'Duration is required']"
                        class="q-autocomplete shadow-0"
                        hide-details="auto"
                    ></v-select>
                  </div>
                  <div  v-if="checkWritePermission($modules.facility.cashbackProducts.slug) ">
                    <v-checkbox
                        v-model="product.allow_cashback"
                        :false-value="0"
                        :true-value="1"
                        label="Allow Cashback"
                        class="qp-custom-checkbox"
                        :ripple="false"

                    ></v-checkbox>
                  </div>

                  <div v-if="isTicket == 1 && checkWritePermission($modules.facility.podProducts.slug)">
                    <v-checkbox
                        v-model="product.is_pod"
                        :false-value="0"
                        :true-value="1"
                        style="margin-top: -1px"
                        label="POD Product"
                        class="qp-custom-checkbox"
                        :ripple="false"
                    ></v-checkbox>
                  </div>
                  <div v-if="product.allow_cashback &&  checkWritePermission($modules.facility.cashbackProducts.slug)">
                    <label for="">Cashback %</label>
                    <v-text-field
                        hide-details="auto"
                        outlined
                        dense
                        append-icon="mdi-percent"
                        background-color="#fff"
                        required
                        v-model="product.cashback_percentage"
                        style="width: 130px"
                        :rules="[
                        (v) => {
                          if (v >= 0) {
                            return true;
                          }
                          return 'Cashback Percentage is required';
                        },
                      ]"
                        class="q-text-field shadow-0"
                    ></v-text-field>
                  </div>

                </div>



                <v-row dense class="border-bottom mt-4 " v-if="isTicket == 1">
                  <v-col cols="12" md="12" lg="12" >
                    <h3  class="text-base font-bold black-text">Sales Channels:</h3>
                  </v-col>
                </v-row>
                <v-row v-if="isTicket == 1" dense >

                  <v-col cols="12">
                    <div class="d-flex gap-x-5 flex-wrap">
                      <v-checkbox
                          v-model="product.enable_online_booking"
                          :false-value="0"
                          :true-value="1"
                          label="Qube (Online Booking)"
                          class="qp-custom-checkbox"
                          :ripple="false"
                      ></v-checkbox>


                      <v-checkbox  v-if="
                      checkReadPermission($modules.b2b.dashboard.slug) &&
                        isTicket == 1
                    "
                          v-model="product.enable_b2b_sales"
                          :false-value="0"
                          :true-value="1"
                          label="B2B"
                          class="qp-custom-checkbox"
                          :ripple="false"
                          style="min-width: 236px"
                      ></v-checkbox>



                      <v-checkbox
                          v-if="
                      checkReadPermission($modules.b2b.dashboard.slug) &&
                        isTicket == 1
                    "
                          v-model="product.enable_b2g_sales"
                          :false-value="0"
                          :true-value="1"
                          label="B2G"
                          class="qp-custom-checkbox"
                          :ripple="false"
                          style="min-width: 236px"

                      ></v-checkbox>



                      <v-checkbox
                          v-model="product.enable_b2e_sales"
                          :false-value="0"
                          :true-value="1"
                          label="B2E"
                          class="qp-custom-checkbox"
                          :ripple="false"
                          style="min-width: 236px"

                          v-if="
                      checkReadPermission($modules.b2b.dashboard.slug) &&
                        isTicket == 1
                    "

                      ></v-checkbox>


                      <v-checkbox
                          v-model="product.enable_scanner_product"
                          :false-value="0"
                          :true-value="1"
                          label="Scanner"
                          class="qp-custom-checkbox"
                          :ripple="false"
                          v-if="
                      (checkReadPermission($modules.vcp.management.slug) ||
                        checkReadPermission($modules.vwd.management.slug)) &&
                        isTicket == 1
                    "
                          style="min-width: 236px"

                      ></v-checkbox>
                        <v-checkbox
                            v-model="product.enable_kiosk_sales"
                            :false-value="0"
                            :true-value="1"
                            label="Kiosk"
                            class="qp-custom-checkbox"
                            style="min-width: 236px"
                            v-if="checkReadPermission($modules.kiosk.facility.slug) && isTicket == 1"
                        ></v-checkbox>
                    </div>
                  </v-col>

                </v-row>



                <v-row dense class="border-bottom mt-4" v-if="isEnableDctErp" >
                  <v-col cols="12" md="12" lg="12" >
                    <h3  class="text-base font-bold black-text">DCT Erp:</h3>
                  </v-col>
                </v-row>

                <v-row v-if="isEnableDctErp" dense class="">
                  <v-col cols="12" sm="6" md="3">
                    <label for="">Project Number *</label>
                    <v-text-field
                        outlined
                        background-color="#fff"
                        v-model="projectNumber"
                        hide-details="auto"
                        required
                        :readonly="projectNumber ? true : false"
                        :rules="[(v) => !!v || 'Project number is required']"
                        class="q-text-field shadow-0"
                        dense

                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label for="">Transaction Type*</label>
                    <v-text-field
                        outlined
                        background-color="#fff"
                        v-model="transactionType"
                        hide-details="auto"
                        placeholder="Transaction Type"
                        required
                        :readonly="transactionType ? true : false"
                        :rules="[(v) => !!v || 'Transaction Type is required']"
                        class="q-text-field shadow-0"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label for="">Task Name*</label>
                    <v-select
                        v-model="product.task_name"
                        item-value="value"
                        item-text="text"
                        hint="Required Task Name"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[(v) => !!v || 'Task name is required']"
                        :items="taskNames"
                        hide-details="auto"
                        outlined
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                    <!-- <v-text-field
                      outlined
                      background-color="#fff"
                      v-model="product.task_name"
                      hide-details="auto"
                      label="Task Name *"
                      placeholder="Task Name"
                      required
                      :rules="[(v) => !!v || 'Task name is required']"
                    ></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label for="">GL Code*</label>
                    <v-select
                        v-model="product.gl_code"
                        item-value="value"
                        item-text="text"
                        hint="Required GL Code"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[(v) => !!v || 'GL code is required']"
                        :items="glCodes"
                        hide-details="auto"
                        outlined
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                    <!-- <v-text-field
                      outlined
                      background-color="#fff"
                      v-model="product.gl_code"
                      hide-details="auto"
                      label="Gl Code *"
                      placeholder="Gl Code"
                      required
                      :rules="[(v) => !!v || 'Gl Code is required']"
                    ></v-text-field> -->
                  </v-col>
                </v-row>

                <v-row dense v-if="checkWritePermission($modules.facility.seasonalPricing.slug)" class="border-bottom mt-4">
                  <v-col cols="12" md="12" lg="12" >
                    <h3  class="text-base font-bold black-text">Seasonal Pricing</h3>
                  </v-col>
                </v-row>


                <template v-if="product.seasonal_pricing && product.seasonal_pricing.length > 0">
                  <v-row dense   v-for="(seasonalPricing,index1) in product.seasonal_pricing" :key="index1" class="mt-4" >
                    <v-col md="3">
                      <label for="">Start Date*</label>
                      <date-field
                          v-model="seasonalPricing.start_date"
                          :rules="[(v) => !!v || 'Start date is required']"
                          dense
                          :backFill="true"
                          class-name="q-text-field shadow-0"
                          label=""
                          :hide-details="true"
                      >
                      </date-field>
                    </v-col>
                    <v-col md="3">
                      <label for="">End Date*</label>
                      <date-field
                          v-model="seasonalPricing.end_date"
                          :minDate="seasonalPricing.start_date"
                          :rules="[(v) => !!v || 'End date is required']"
                          dense
                          class-name="q-text-field shadow-0"
                          label=""
                          :hide-details="true"


                      >
                      </date-field>
                    </v-col>
                    <v-col md="2">
                      <label for="">Price (Pre Tax)*</label>
                      <v-text-field
                          outlined
                          hide-details="auto"
                          dense
                          rows="2"
                          :prefix="currencyCode"
                          background-color="#fff"
                          required
                          v-model="seasonalPricing.pre_tax_price"
                          @change="calculateSeasonalPricingTaxVariation(index, index1,$event, 'pre')"
                          class="q-text-field shadow-0"
                          :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                  ]"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <label for="">Price (Post Tax)*</label>

                      <v-text-field
                          outlined
                          hide-details="auto"
                          rows="2"
                          :prefix="currencyCode"
                          background-color="#fff"
                          dense
                          required
                          v-model="seasonalPricing.total_price"
                          @change="calculateSeasonalPricingTaxVariation(index, index1,$event, 'post')"
                          class="q-text-field shadow-0"
                          :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                  ]"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              @click="deleteSeasonalPricing(index,index1)"
                              fab
                              x-small
                              dark
                              class="mt-6"
                              icon
                          >
                           <DeleteIcon/>
                          </v-btn>
                        </template>
                        Delete
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>
                <v-row dense class="mt-4" >
                  <v-col cols="12">
                    <p class="text-neon text-base pointer font-medium m-0 p-0 text-underline"  @click="addSeasonalPricing(index)">+ Add pricing </p>
                  </v-col>
                </v-row>

                <v-row dense class="border-bottom mt-4"     v-if="
                  isTicket == 1 &&
                    checkWritePermission($modules.memberships.management.slug)
                ">
                  <v-col cols="12" md="12" lg="12" >
                    <h3  class="text-base font-bold black-text">Customer Eligibility:</h3>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <div class="d-flex gap-x-5">
                      <div
                          v-if="
                  isTicket == 1 &&
                    checkWritePermission($modules.memberships.management.slug)
                "
                      >
                        <v-radio-group
                            class="d-flex"
                            v-model="product.is_membership_only"
                            row
                        >
                          <v-radio
                              label="All Customers"
                              color="red"
                              :value="0"
                              class="custom-radio"

                          ></v-radio>
                          <v-radio
                              label="Members Only"
                              color="red"
                              :value="1"
                              class="custom-radio"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                          v-if="
                  isTicket == 1 &&
                    checkWritePermission($modules.memberships.management.slug)
                "
                      >
                        <label for="" v-if="product.is_membership_only == 1">Memberships</label>
                        <v-select
                            v-if="product.is_membership_only == 1"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            :items="memberships"
                            item-text="name"
                            item-value="membership_id"
                            multiple
                            v-model="product.membership_ids"
                            :rules="[(v) => !!v[0] || 'Membership is required']"
                            class="q-autocomplete shadow-0"
                            dense
                            hide-details="auto"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>

                </v-row>
              </v-card-text>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      @click="deleteRentals(index)"
                      fab
                      x-small
                      absolute
                      top
                      right
                      dark
                      icon
                  >
                   <DeleteIcon/>
                  </v-btn>
                </template>
                Deletes
              </v-tooltip>
            </v-card>
            <v-row>
              <v-col cols="12">
                <v-btn color="teal-color" class="shadow-0"  dark @click="addRentalProduct">
                  + Add New Ticket
                </v-btn>
              </v-col>
            </v-row>


          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
            v-if="
          checkWritePermission($modules.facility.management.slug) &&
            venueServices.length && rentalProducts.length
        "
            class="ma-2  white--text blue-color"
            @click="saveOrUpdateRentalProducts()"
            text
        >Update</v-btn
        >
      </v-row>
    </v-container>

    <timing-templates
        v-if="timingDialog"
        :showTimingTemplate="timingDialog"
        :venueServiceId="venueServiceId"
        @close="timingDialog = false"
    />
    <view-image-popup
        :image_path="currentImage.image_path"
        :data_url="currentImage.data_url"
        @resetImagePath="resetImagePath"
    ></view-image-popup>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import TimingTemplates from "@/components/Facility/TimingTemplates.vue";
import ViewImagePopup from "@/components/Image/ViewImagePopup";
import constants from "@/utils/constants";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";

export default {
  components: {
    FacilitiesTabs,
    ImageUploader,
    TimingTemplates,
    ViewImagePopup,
    DeleteIcon
  },
  data() {
    return {
      defaultTaxId: null,
      rentalProducts: [
        {
          weekdays: [],
          seasonal_pricing:[
            {
              start_date:null,
              end_date:null,
              pre_tax_price:null,
              total_price:null,
            }
          ],
        },
      ],
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      radioGroupRules: [ // Define validation rules
        v => !!v || 'Ticket type is required' // Required rule
        // Add additional custom rules as needed
      ],
      confirmModel: { id: null, title: null, description: null },
      viewImagePopupDialog: false,
      deletedProducts: [],
      deletedSeasonalPricing:[],
      configurations: {},
      duration: [],
      venueServiceId: null,
      timingDialog: false,
      currentImage: {},
      isTicket: 0,
      membership_ids: [],
      serviceMemberships: [],
      taskNames: constants.TASK_NAMES,
      glCodes: constants.GL_CODES,
      projectNumber: null,
      transactionType: null,
      isEnableDctErp: false,
      isEnableOpenDated: false,
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.venueInfo) {
      if (this.$store.getters.venueInfo.enable_dct_erp) {
        this.isEnableDctErp = true;
        if (this.$store.getters.venueInfo.dct_erp_configuration) {
          this.projectNumber = this.$store.getters.venueInfo.dct_erp_configuration.project_no;
          this.transactionType = this.$store.getters.venueInfo.dct_erp_configuration.transaction_type;
        }
      } else {
        this.isEnableDctErp = false;
      }
      if (this.$store.getters.venueInfo.enable_open_dated_tickets) {
        this.isEnableOpenDated = true;
      } else {
        this.isEnableOpenDated = false;
      }
    }
    this.loadServiceMemberships();
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    memberships() {
      return this.serviceMemberships;
    },
    // isEnableDctErp() {
    //   console.log("this.$store.getters.venueInfo");
    //   console.log(this.$store.getters.venueInfo);
    //   if (this.$store.getters.venueInfo) {
    //     this.projectNumber = this.$store.getters.venueInfo.dct_erp_configuration.project_no;
    //     return this.$store.getters.venueInfo.enable_dct_erp;
    //   }
    //   return 0;
    // },
  },
  methods: {


    addSeasonalPricing(index){
      this.rentalProducts[index].seasonal_pricing.push({
        start_date:null,
        end_date:null,
        pre_tax_price:null,
        total_price:null,
      });
    },
    deleteSeasonalPricing(index,subIndex){
      if(this.rentalProducts[index].seasonal_pricing[subIndex].id){
        this.confirmModel = {
          id: subIndex,
          data: {index:index, subIndex: subIndex},
          title: "Do you want to delete this seasonal pricing?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "deleteSeasonalPricing",
        };
      }else{
        this.rentalProducts[index].seasonal_pricing.splice(subIndex, 1);
      }
    },
    serviceChange() {
      this.loadServiceMemberships();
      this.getRentalProducts();
      this.initializeFacilityServices();
    },
    rentalChange(id) {
      this.rentalProducts[id].timing_template = null;
      this.rentalProducts[id].timing_template_id = null;
    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
        this.isTicket = data.is_ticket;
      } else {
        if (this.$store.getters.getSportsService.length) {
          this.venueServiceId = this.$store.getters.getSportsService[0].venue_service_id;
        }
      }
      setTimeout(() => {
        this.getRentalProducts();
        this.initializeFacilityServices();
      }, 10);
    },
    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
            .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
            .then((response) => {
              if (response.status == 200) {
                let data = this.$store.getters.getConfigurationByVenueServiceId(
                    this.venueServiceId
                );
                this.configurations = data;
                this.getMinBookingTimes();
                this.defaultTaxId = data.default_tax_id;
                if (this.defaultTaxId && this.rentalProducts[0] && !this.rentalProducts[0]?.tax_type_id) {
                  this.rentalProducts[0].tax_type_id = this.defaultTaxId;
                }
              }
            });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
            this.venueServiceId
        );
        this.configurations = data;
        this.getMinBookingTimes();
        this.defaultTaxId = data.default_tax_id;
        if (this.defaultTaxId && !this.rentalProducts[0].tax_type_id) {
          this.rentalProducts[0].tax_type_id = this.defaultTaxId;
        }
      }
    },
    toggle(index) {
      let field = "weekdays_value";
      this.$nextTick(() => {
        if (this.rentalProducts[index][field].length == this.weekdays.length) {
          this.rentalProducts[index][field] = [];
        } else {
          this.rentalProducts[index][field] = this.weekdays.map(
              (item) => item.bit_value
          );
        }
      });
      this.$forceUpdate();
    },

    getIcon(index) {
      let field = "weekdays_value";
      let icon = "mdi-checkbox-blank-outline";
      if (!this.rentalProducts[index][field]) {
        this.rentalProducts[index]["weekdays_value"] = [];
      }
      if (
          this.rentalProducts[index][field] &&
          this.rentalProducts[index][field].length == this.weekdays.length
      ) {
        icon = "mdi-close-box";
      }
      if (
          this.rentalProducts[index][field] &&
          this.rentalProducts[index][field].length > 0 &&
          this.rentalProducts[index][field].length != this.weekdays.length
      )
        icon = "mdi-minus-box";

      return icon;
    },

    // timingTemplateChange(index, data) {
    //   this.product.facility_rentals[index].timing_template_id = data.id;

    //   if (data.id != null) {
    //     this.product.facility_rentals[index].weekdays = data.weekdays;
    //     this.product.facility_rentals[index].start_time = data.start_time;
    //     this.product.facility_rentals[index].end_time = data.end_time;
    //   } else {
    //     this.product.facility_rentals[index].weekdays = [];
    //     this.product.facility_rentals[index].start_time = null;
    //     this.product.facility_rentals[index].end_time = null;
    //   }
    // },
    getRentalProducts() {
      this.showLoader("Loading");
      this.$store
          .dispatch("loadRentalProductsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              if (data && data.length) {
                let filter = this.isTicket == 0 ? "Rental" : "Ticket";
                this.rentalProducts = data.filter(
                    (x) => x.category_name == filter
                );
                this.rentalProducts.map((element) => {
                  if (element.is_membership_only == 1) {
                    element.membership_ids = element.product_memberships.map(
                        (x) => x.membership_id
                    );
                  } else {
                    element.membership_ids = [];
                  }

                  let p = this.rentalProducts[0];
                  if (p.project_no) {
                    this.projectNumber = p.project_no;
                  }
                  if (p.transaction_type) {
                    this.transactionType = p.transaction_type;
                  }

                  // if (element.ticket_type == null) {
                  //   element.ticket_type = "I";
                  // }

                  return element;
                });
              }
              // if (this.rentalProducts.length == 0) {
              //   this.rentalProducts = [
              //     { tax_type_id: this.defaultTaxId, weekdays_value: [] },
              //   ];
              // }
              if (data.length == 0) {
                this.rentalProducts = [
                  { tax_type_id: this.defaultTaxId, weekdays_value: [] ,seasonal_pricing:[
                      {
                        start_date:null,
                        end_date:null,
                        pre_tax_price:null,
                        total_price:null,
                      }
                    ]},
                ];
              }
              setTimeout(() => {
                this.$refs.form?.resetValidation();
              });
              this.dialog = true;
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    saveOrUpdateRentalProducts() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      let formData = new FormData();
      if (this.deletedProducts.length) {
        this.deletedProducts.forEach((id, index) => {
          formData.append(`deleted_products[${index}]`, id);
        });

      } if (this.deletedSeasonalPricing.length) {
        this.deletedSeasonalPricing.forEach((id, index) => {
          formData.append(`deleted_seasonal_pricing[${index}]`, id);
        });
      }
      this.rentalProducts.forEach((product, index) => {
        formData.append(`products[${index}][name]`, product.name);
        if (product.ar_name) {
          formData.append(`products[${index}][ar_name]`, product.ar_name);
        }
        formData.append(`products[${index}][duration]`, product.duration);
        formData.append(`products[${index}][price]`, product.price);
        formData.append(`products[${index}][tax_type_id]`, product.tax_type_id);

        if(product.seasonal_pricing && product.seasonal_pricing.length > 0){
          product.seasonal_pricing.forEach((pricing, index1) => {
            if(pricing.id){
              formData.append(`products[${index}][seasonal_pricing][${index1}][id]`, pricing.id);
            }
            formData.append(`products[${index}][seasonal_pricing][${index1}][start_date]`, pricing.start_date);
            formData.append(`products[${index}][seasonal_pricing][${index1}][end_date]`, pricing.end_date);
            formData.append(`products[${index}][seasonal_pricing][${index1}][pre_tax_price]`, pricing.pre_tax_price);
            formData.append(`products[${index}][seasonal_pricing][${index1}][total_price]`, pricing.total_price);
          });
        }

        if (this.isTicket == 1) {
          formData.append(
              `products[${index}][is_open_dated]`,
              product.is_open_dated
          );
          if (product.is_open_dated == 1) {
            formData.append(
                `products[${index}][expiry_days]`,
                product.expiry_days
            );
          } else {
            product.expiry_days = null;
          }
          formData.append(
              `products[${index}][is_membership_only]`,
              product.is_membership_only
          );
          if (product.is_membership_only == 1) {
            formData.append(
                `products[${index}][membership_ids]`,
                product.membership_ids
            );
          } else {
            product.membership_ids = [];
          }
          formData.append(
              `products[${index}][ticket_type]`,
              product.ticket_type
          );
          formData.append(
              `products[${index}][enable_online_booking]`,
              product.enable_online_booking
          );
          // formData.append(
          //   `products[${index}][enable_kiosk_sales]`,
          //   product.enable_kiosk_sales
          // );
          formData.append(
              `products[${index}][enable_b2b_sales]`,
              product.enable_b2b_sales
          );
          formData.append(
              `products[${index}][enable_b2g_sales]`,
              product.enable_b2g_sales
          );
          formData.append(
              `products[${index}][enable_b2e_sales]`,
              product.enable_b2e_sales
          );
          formData.append(
              `products[${index}][enable_scanner_product]`,
              product.enable_scanner_product
          );
          formData.append(
            `products[${index}][enable_kiosk_sales]`,
            product.enable_kiosk_sales
          );

          formData.append(
              `products[${index}][participant_count]`,
              product.ticket_type == "I"
                  ? 1
                  : product.ticket_type == "C"
                      ? 2
                      : product.ticket_type == "G"
                          ? product.participant_count
                          : 0
          );
        }

        if(product.allow_cashback){
          formData.append(
              `products[${index}][allow_cashback]`,
              product.allow_cashback
          );
        }
        if(product.cashback_percentage){
          formData.append(
              `products[${index}][cashback_percentage]`,
              product.cashback_percentage
          );
        }

        formData.append(
            `products[${index}][benefit_excluded]`,
            product.benefit_excluded
        );
        formData.append(
            `products[${index}][duplicate_booking]`,
            product.duplicate_booking
        );

        if (product.duplicate_booking == 1) {
          formData.append(
              `products[${index}][duplicate_booking_duration]`,
              product.duplicate_booking_duration
          );
        }
        formData.append(
            `products[${index}][is_pod]`,
            product.is_pod
        );
        if (product.specific_day == 1) {
          formData.append(
              `products[${index}][specific_day]`,
              product.specific_day
          );
          formData.append(
              `products[${index}][weekdays_value]`,
              product.weekdays_value
          );
        }
        if (product.product_id) {
          formData.append(`products[${index}][product_id]`, product.product_id);
        }
        if (product.file) {
          formData.append(`products[${index}][image]`, product.file);
        }
        formData.append(
            `products[${index}][show_end_time]`,
            product.show_end_time
        );

        //erp fields
        if (this.isEnableDctErp) {
          if (this.projectNumber) {
            formData.append(
                `products[${index}][project_no]`,
                this.projectNumber
            );
          }
          if (product.task_name) {
            formData.append(`products[${index}][task_name]`, product.task_name);
          }
          if (product.gl_code) {
            formData.append(`products[${index}][gl_code]`, product.gl_code);
          }
          if (this.transactionType) {
            formData.append(
                `products[${index}][transaction_type]`,
                this.transactionType
            );
          }
        }
      });
      formData.append(`per_capacity`, this.isTicket);
      this.showLoader();
      this.$http
          .post(
              `venues/facilities/categories/rentals/${this.venueServiceId}`,
              formData
          )
          .then((response) => {
            if (response.status == 200) {
              // this.$store.dispatch(
              //   "loadRentalProductsByVenueServiceId",
              //   this.venueServiceId
              // );
              this.getRentalProducts();
              this.hideLoader();
              if (this.isTicket == 1) {
                this.showSuccess("Ticket pricing updated successfully");
              } else {
                this.showSuccess("Time Rates updated successfully");
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    deleteRentals(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this product?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    confirmActions(data) {

      if (data.type == "delete") {
        let product = this.rentalProducts[data.id];
        if (product.id) {
          this.deletedProducts.push(product.rental_product_id);
        }
        this.rentalProducts.splice(data.id, 1);
        if (this.rentalProducts.length == 0) {
          this.rentalProducts = [{}];
        }
      }
      if (data.type == "deleteSeasonalPricing") {
        let indices = data.data;
        let pricing = this.rentalProducts[indices.index].seasonal_pricing[indices.subIndex];
        if (pricing.id) {
          this.deletedSeasonalPricing.push(pricing.id);
        }
        this.rentalProducts[indices.index].seasonal_pricing.splice(indices.subIndex, 1);
      }
      this.confirmModel.id = null;
    },
    addRentalProduct() {
      this.rentalProducts.push({
        tax_type_id: this.defaultTaxId,
        is_open_dated: 0,
        allow_cashback: 0,
        cashback_percentage: 0,
        seasonal_pricing:[
          // {
          //   start_date:null,
          //   end_date:null,
          //   pre_tax_price:null,
          //   total_price:null,
          // }
        ]
      });
    },

    getMinBookingTimes() {
      this.duration = [];
      const timeIncrement = this.configurations.time_increment;
      if (timeIncrement) {
        let hour = 0;
        let index = 0;
        while (hour < 24) {
          index = index + 1;
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min =
              time / 60 - hour > 0 ? Math.round((time / 60 - hour) * 60) : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${min} Min` : "";
          this.duration.push({ value: time, text: text });
        }
      }
      if (this.configurations.per_capacity == 0) {
        const hasObject = this.duration.some(
            (obj) => obj.value === 60 && obj.text === "Full day"
        );

        // If the object doesn't exist, add it to the array
        if (!hasObject) {
          this.duration.push({ value: 2440, text: "Full day" });
        }
      }
      if (this.configurations.per_capacity == 1) {
        const hasObject = this.duration.some(
            (obj) => obj.value === 1 && obj.text === "No Duration"
        );

        // If the object doesn't exist, add it to the array
        if (!hasObject) {
          this.duration.unshift({ value: 1, text: "No Duration" });
        }
      }
    },
    calculateTaxVariation(index, amount, type) {
      let taxTypeId = this.rentalProducts[index].tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.rentalProducts[index].price = priceData.price;
        this.rentalProducts[index].pre_tax_price = priceData.price.toFixed(2);
        this.rentalProducts[index].total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },
    calculateSeasonalPricingTaxVariation(index, subIndex, amount, type) {
      let taxTypeId = this.rentalProducts[index].tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.rentalProducts[index].seasonal_pricing[subIndex].price = priceData.price;
        this.rentalProducts[index].seasonal_pricing[subIndex].pre_tax_price = priceData.price.toFixed(2);
        this.rentalProducts[index].seasonal_pricing[subIndex].total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },
    taxChange(index) {
      if (this.rentalProducts[index].price) {
        this.calculateTaxVariation(
            index,
            this.rentalProducts[index].price,
            "pre"
        );
      } else if (this.rentalProducts[index].total_price) {
        this.calculateTaxVariation(
            index,
            this.rentalProducts[index].total_price,
            "post"
        );
      }

      this.rentalProducts[index].seasonal_pricing.forEach((pricing,index1) => {
        if(this.rentalProducts[index].seasonal_pricing.length > 0){
          if(this.rentalProducts[index].seasonal_pricing[index1].pre_tax_price){
            this.calculateSeasonalPricingTaxVariation(index,index1,this.rentalProducts[index].seasonal_pricing[index1].pre_tax_price,'pre')
          }else if(this.rentalProducts[index].seasonal_pricing[index1].total_price){
            this.calculateSeasonalPricingTaxVariation(index,index1,this.rentalProducts[index].seasonal_pricing[index1].total_price,'post')
          }
        }
      })

    },

    gotoConfiguration() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityConfiguration",
        params: {
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
        },
      });
    },
    gotoCategoryProducts() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityProductsPerService",
        params: {
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
        },
      });
    },
    gotoFacilities() {
      this.$router.push({
        name: "FacilityPerService",
        params: {
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
        },
      });
    },
    gotToFacilityForm() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityForm",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service_id: this.venueServiceId,
                service: this.venueServices.find(
                    (item) => item.venue_service_id == this.venueServiceId
                ).name,
              })
          ),
        },
      });
    },
    showPopupImage(product) {
      this.currentImage = {
        image_path: product.image,
        data_url: product.data_url,
      };
    },
    resetImagePath() {
      this.currentImage = {};
    },
    gotoRentalTicketProducts(val) {
      this.isTicket = val;
      this.getRentalProducts();
    },
    loadServiceMemberships() {
      if (
          this.checkWritePermission(this.$modules.memberships.management.slug)
      ) {
        let url = `&venue_service_ids[0]=${this.venueServiceId}`;
        this.$http
            .get(`venues/memberships?page=1&status_id=1${url}`)
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.hideLoader();
                //this.membershipList = response.data.data;
                this.serviceMemberships = response.data.data.map((item) => {
                  return { membership_id: item.id, name: item.name };
                });
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      } else {
        this.serviceMemberships = [];
      }
    },
  },
  watch:{
    venueServiceId(){
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        console.log(data,'data')
        this.isTicket = data.is_ticket;
      }
    }
  }
};
</script>
<style scoped>
span.sales_heading {
  margin-left: 1%;
  margin-top: 1%;
  line-height: 20px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}
span.seasonal_heading {
  margin-left: 1%;
  margin-top: 1%;
  line-height: 20px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}
span.empty-seasonal-price{
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin-bottom: 10px;
}


.tab_is_active{
  color: #112A46;
  font-weight: 600;
  svg{
    opacity: 1 !important;
  }
}


.q-tab-nav{
  svg{
    fill: none !important;
    stroke: black !important;
    opacity: 0.4;
    min-width: 20px;
  }
}
</style>
